define("discourse/plugins/waze-forms/discourse/connectors/category-custom-settings/waze-forms-settings", ["exports", "discourse/models/group", "I18n"], function (_exports, o, e) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var t = {
      d: (o, e) => {
        for (var s in e) t.o(e, s) && !t.o(o, s) && Object.defineProperty(o, s, {
          enumerable: !0,
          get: e[s]
        });
      },
      o: (o, e) => Object.prototype.hasOwnProperty.call(o, e)
    },
    s = {};
  t.d(s, {
    A: () => i
  });
  const l = (a = {
    default: () => o.default
  }, r = {}, t.d(r, a), r);
  var a, r;
  const n = (o => {
      var e = {};
      return t.d(e, o), e;
    })({
      default: () => e.default
    }),
    i = {
      setupComponent() {
        var o, e, t;
        const s = (null === (e = null === (o = n.default.translations.en.js.waze) || void 0 === o ? void 0 : o.forms) || void 0 === e ? void 0 : e.create_topic_label) || {},
          a = Object.keys(s).map(o => ({
            id: o,
            name: n.default.t(`js.waze.forms.create_topic_label.${o}`) || s[o]
          }));
        this.set("wazeFormsComposeButtonOptions", a), l.default.findAll().then(o => {
          this.set("groups", o.map(o => o.name));
        });
        const r = null === (t = this.category.custom_fields) || void 0 === t ? void 0 : t.groups_allowed_to_open_logs;
        r ? this.set("groupsAllowedToOpenLogs", r.split("|")) : this.set("groupsAllowedToOpenLogs", []);
      },
      actions: {
        changeGroupsAllowedToOpenLogs: function (o) {
          this.set("groupsAllowedToOpenLogs", o), this.category.set("custom_fields", Object.assign(Object.assign({}, this.category.custom_fields), {
            groups_allowed_to_open_logs: o.join("|")
          }));
        }
      }
    };
  var d = _exports.default = s.A;
});